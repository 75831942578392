<template>
  <div class="compensate">
    <el-dialog title="售后" :visible="dialogVisible === -1" width="300px" :show-close="false">
      <div class="inner compensateType">
        <div class="coupon" v-if="compensateType === 0">
          {{ couponDict[couponId] }}
        </div>
        <div class="feedback" v-if="compensateType === 2">{{ feedback }}</div>
        <el-radio-group v-model="compensateType">
          <el-radio :label="0">优惠券</el-radio>
          <el-radio :label="1" disabled>积分</el-radio>
          <el-radio :label="2">反馈语</el-radio>
          <el-radio :label="3">不补偿</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('changeShow', false)">取 消</el-button>
        <el-button type="primary" @click="postCompensate">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="请选择优惠券" :visible="dialogVisible === 0" width="300px" :show-close="false">
      <div class="inner">
        <el-radio-group v-model="couponId">
          <el-radio :label="coupon.id" v-for="coupon in couponList" :key="coupon.id">{{ coupon.couponName }}</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelChildren">取 消</el-button>
        <el-button type="primary" @click="couponOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="请输入反馈语" :visible="dialogVisible === 2" width="300px" :show-close="false">
      <el-input type="textarea" :rows="3" placeholder="请输入内容" :maxlength="30" v-model.trim="feedback"> </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelChildren">取 消</el-button>
        <el-button type="primary" @click="feedbackOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    afterRefundInfo: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      couponList: [], // 优惠券列表
      couponDict: {}, // 优惠券对象
      dialogVisible: -1, // 控制展示，-1，选售后类型，0，补券，2，反馈语
      compensateType: -1, // 补偿类型
      couponId: '', // 选中的优惠券Id
      feedback: '' //反馈语
    }
  },
  computed: {
    ...mapGetters({})
  },
  watch: {
    compensateType() {
      if (this.compensateType === 3) return false
      this.dialogVisible = this.compensateType
    }
  },
  methods: {
    ...mapActions({}),
    // 提交退款补偿
    postCompensate() {
      let params = {
        compensateType: this.compensateType,
        memberId: this.afterRefundInfo.memberId,
        orderRefundId: this.afterRefundInfo.id
      }
      if (this.compensateType === -1) {
        this.$message.error('请先选择补偿类型')
        return false
      }
      if (this.compensateType === 0) {
        params.couponId = this.couponId
      }
      if (this.compensateType === 2) {
        params.feedback = this.feedback
      }
      this.$http
        .post('boom-mix-biz-service/sysAdmin/orderRefundCompensate', params)
        .then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.$emit('getList')
          this.$emit('changeShow', false)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    // 取消子级选择
    cancelChildren() {
      this.dialogVisible = -1
      this.compensateType = -1
      this.couponId = ''
      this.feedback = ''
    },
    //  获取优惠券列表
    getCouponList() {
      this.$http
        .post('boom-center-marketing-service/sysAdmin/coupon/list', {
          auditStatus: 2,
          couponState: 2,
          couponType: 0,
          page: 1,
          size: 1000
        })
        .then(res => {
          let time = new Date()
          time = time.getTime()
          this.couponList = res.list.filter(v => {
            return v.receiveStartTime < time && v.receiveEndTime > time
          })
          this.couponList.map(v => {
            this.couponDict[v.id] = v.couponName
          })
        })
    },
    // 优惠券OK
    couponOk() {
      if (this.couponId === '') {
        this.$message.error('请先选择优惠券')
        return false
      }
      this.dialogVisible = -1
    },
    // 反馈语OK
    feedbackOk() {
      if (this.feedback === '') {
        this.$message.error('请先填写反馈语')
        return false
      }
      this.dialogVisible = -1
    }
  },
  created() {
    this.getCouponList()
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.inner {
  overflow-y: auto;
  height: 200px;
  box-shadow: 0 0 5px #ccc;
  .el-radio-group {
    .el-radio {
      .ellipsis(1);
      width: 100%;
      margin: 20px 30px 0 30px;
    }
  }
}
.compensateType {
  position: relative;
  .coupon,
  .feedback {
    position: absolute;
    top: 131px;
    left: 60px;
    color: #999;
    .ellipsis(1);
  }
  .coupon {
    top: 39px;
  }
  .el-radio-group .el-radio {
    padding-bottom: 10px;
  }
}
</style>
