<route>
{
  "meta": {
    "auth": "afterRefund"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer" @keyup.enter.native="getList">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="6">
          <span class="searchText">订单编号</span>
          <el-input
            class="searchInput"
            placeholder="订单编号"
            oninput="value=value.replace(/[^0-9]/g,'')"
            v-model="search.orderId"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="6">
          <span class="searchText">会员手机号</span>
          <el-input class="searchInput" placeholder="请输入会员手机号" v-model.trim="search.mobile" clearable>
          </el-input>
        </el-col>
        <el-col :span="6">
          <span class="searchText">退款原因</span>
          <el-cascader
            :options="searchRejectReasonList"
            class="searchInput"
            clearable
            @change="option => handleChange(option, search, 'refundReasonId')"
          ></el-cascader>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="6">
          <span class="searchText">商户名称</span>
          <el-input class="searchInput" placeholder="请输入商户名称 " v-model="search.businessName" clearable>
          </el-input>
        </el-col>
        <el-col :span="6">
          <span class="searchText">购买人昵称</span>
          <el-input class="searchInput" placeholder="请输入购买人昵称" v-model.trim="search.memberName" clearable>
          </el-input>
        </el-col>
        <el-col :span="6">
          <span class="searchText">购买人ID</span>
          <el-input
            class="searchInput"
            placeholder="请输入购买人ID "
            oninput="value=value.replace(/[^0-9]/g,'')"
            v-model="search.memberId"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="6" style="padding-top: 10px">
          <el-button type="info" @click="reset">重置</el-button>
          <el-button type="primary" @click="getList"> 搜索</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix" style="position: relative">
        <span style="opacity: 0">.</span>
        <el-tabs type="card" v-model="search.afterSaleType" style="position: absolute; top: -1px; left: -21px">
          <el-tab-pane label="全部" name="1"></el-tab-pane>
          <el-tab-pane :label="`待处理（${pendingNum}）`" name="0"></el-tab-pane>
        </el-tabs>
        <el-button size="mini" style="float: right; margin-right: 10px" type="success" @click="downLoadExcel"
          >导出退款记录</el-button
        >
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%" class="table">
        <el-table-column v-for="col in Object.keys(labels)" :key="col" :prop="col" :label="labels[col]" align="center">
          <template slot-scope="{ row }">
            <template v-if="col === 'info'">
              <div class="product-info">
                <img :src="row[col].skuPic | thumbMedium" />
                <div class="info">{{ row[col].skuName }}</div>
              </div>
            </template>
            <template v-else-if="col === 'refundInfo'">
              <div class="item">实付总金额：{{ row[col].actualPayMoney }}</div>
              <div class="item">商品售价：{{ row[col].skuMoney }}</div>
            </template>
            <template v-else-if="col === 'applyRefundTime'">
              {{ new Date(row[col]).toLocaleString() }}
            </template>

            <template v-else-if="col === 'areaId'">
              {{ row[col] | formatArea }}
            </template>
            <template v-else-if="col === 'saleNameList' || col === 'saleTypeList' || col === 'exchangeTypeList'">
              <span v-for="(item, index) in row[col]" :key="item"
                >{{ item }}{{ row[col].length == index + 1 ? '' : ',' }}</span
              >
            </template>
            <template v-else-if="col === 'verifyStatus'">
              {{ row[col] === 1 ? '已退款' : row[col] === 2 ? '已拒绝' : '已取消' }}
            </template>

            <template v-else-if="col === 'afterSaleType'">
              {{ row[col] === 1 ? '已处理' : '待处理' }}
            </template>
            <template v-else>{{ row[col] }}</template>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="check(scope.row)" type="text" size="small"> 详情</el-button>
            <el-button
              type="text"
              size="small"
              @click="changeShow(true, scope.row)"
              v-if="scope.row.afterSaleType === 0"
            >
              售后</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="退款记录导出"
      :visible.sync="download.show"
      append-to-body
      width="30%"
    >
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 80px"> 时间</span>
        <el-date-picker
          value-format="timestamp"
          v-model="download.time"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>

        <div style="margin-top: 20px">
          <span style="display: inline-block; width: 80px"> 退款原因</span>
          <el-cascader
            :options="searchRejectReasonList"
            class="searchInput"
            clearable
            @change="option => handleChange(option, download, 'refundReasonId')"
          ></el-cascader>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="download.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmDownLoadExcel">确 定</el-button>
      </span>
    </el-dialog>
    <com-Compensate
      :afterRefundInfo="afterRefundInfo"
      @getList="sleepGetList"
      @changeShow="changeShow"
      v-if="compensateShow"
    ></com-Compensate>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import comCompensate from './__com__/compensate.vue'
export default {
  mixins: [pagination],
  data() {
    return {
      searchRejectReasonList: [],
      reasonTypeList: [], // 退款分类列表
      status: '',
      pendingNum: 0, // 待处理数量
      compensateShow: false, // 是否展示子组件
      afterRefundInfo: {}, // 需要售后的退款明细
      search: {
        orderId: '',
        mobile: '',
        refundReasonId: '',
        businessName: '',
        memberName: '',
        memberId: '',
        afterSaleType: '1'
      },
      labels: {
        // index: '序号',
        orderId: '交易单号',
        info: '款式信息',
        memberName: '购买人昵称',
        areaId: '地区',
        memberId: '购买人ID',
        refundReason: '退款原因',
        refundNum: '退款数量',
        refundInfo: '退款信息',
        applyRefundTime: '发起时间',
        saleNameList: '销售名称',
        saleTypeList: ' 销售类型',
        exchangeTypeList: ' 抽佣类型',
        businessName: ' 商户名称',
        applyRefundTime: '发起时间',
        afterSaleType: '状态'
      },
      download: {
        show: false,
        url: '',
        time: [],
        refundReasonId: ''
      },
      list: []
    }
  },
  components: {
    comCompensate
  },
  methods: {
    downLoadExcel() {
      this.download = {
        show: true,
        url: '/boom-center-statistics-service/sysAdmin/exportExl/orderRefund?',
        time: [],
        refundReasonId: ''
      }
    },
    confirmDownLoadExcel() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let status = `1&verifyStatusList=2&verifyStatusList=3`

      let time = this.download.time.length
        ? `&applyRefundTimeEnd=${this.download.time[1]}&applyRefundTimeStart=${this.download.time[0]}`
        : ''

      let params = `&verifyStatusList=${status}${time}&refundReasonId=${this.download.refundReasonId}`

      if (this.search.afterSaleType === '0') {
        params += `&afterSaleType=0`
      }

      this.$http
        .get(this.download.url + params)
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    getRefundReasonList() {
      this.$http
        .get('boom-center-admin-service/sysAdmin/refundReason/list')
        .then(res => {
          let reasonType = {}
          this.searchRejectReasonList = []
          res.map(v => {
            if (!reasonType[v.typeName]) reasonType[v.typeName] = []
            reasonType[v.typeName].push({
              value: v.id,
              label: v.name
            })
          })
          for (let key in reasonType) {
            this.searchRejectReasonList.push({
              value: key,
              label: key,
              children: reasonType[key]
            })
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    // 改变退款原因
    handleChange(option, obj, name) {
      obj[name] = option[1] ? option[1] : ''
    },
    check(row) {
      this.$router.push(
        `/financial/refundApply/refundParticulars?id=${row.orderId}&refundId=${row.id}&memberId=${row.memberId}&type=refundDetail`
      )
    },
    reset() {
      this.search = {
        orderId: '',
        mobile: '',
        refundReasonId: '',
        businessName: '',
        memberName: '',
        memberId: '',
        afterSaleType: this.search.afterSaleType
      }
      this.getList(false, true)
    },
    getList(val, reset) {
      // 列表基于开启售后的退款分类，没有的话，就不搜索了
      if (this.reasonTypeList.length <= 0) return false
      this.loading = true
      // 判断是否是分页还是普通搜索
      this.currentPage = val === true ? this.currentPage : 1

      if (this.search.time) {
        this.search.applyRefundTimeStart = this.search.time[0]
        this.search.applyRefundTimeEnd = this.search.time[1]
      } else {
        this.search.applyRefundTimeStart = ''
        this.search.applyRefundTimeEnd = ''
      }

      let { time, ...payload } = this.search

      let config =
        reset === true
          ? {}
          : {
              headers: {
                ['Bm-Area-Id']: sessionStorage.getItem('currentAreaId')
              }
            }
      let params = `page=${this.currentPage}&size=${this.pageSize}&memberId=${payload.memberId}&memberName=${payload.memberName}&orderId=${payload.orderId}&refundReasonId=${payload.refundReasonId}&mobile=${payload.mobile}&businessName=${payload.businessName}&productRefundReasonTypeSortLE=1&verifyStatusList=1`

      if (this.search.afterSaleType === '0') {
        params += `&afterSaleType=0`
      }

      this.reasonTypeList.map(v => {
        params += `&refundReasonTypeIdList=${v.id}`
      })

      this.$http
        .get('boom-center-search-service/sysAdmin/orderRefund/page?' + params, config)
        .then(res => {
          this.total = res.total
          res.list.map(item => {
            item.info = {
              skuPic: item.skuPic,
              skuName: item.skuName
            }
            item.refundInfo = {
              actualPayMoney: item.actualPayMoney,
              skuMoney: item.skuMoney
            }
          })
          this.list = res.list
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    sleepGetList() {
      setTimeout(() => {
        this.getList(true)
      }, 1000)
    },
    // 获取退款分类列表
    getReasonTypeList() {
      this.$http
        .get('boom-center-admin-service/sysAdmin/refundReasonType/page', {
          params: {
            page: 1,
            size: 1000,
            afterSalesService: 1
          }
        })
        .then(res => {
          this.reasonTypeList = res.list
          this.getList()
          this.getNum()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    // 获取待处理数量
    getNum() {
      if (this.reasonTypeList.length <= 0) return false
      let params = `page=1&size=1&afterSaleType=0&productRefundReasonTypeSortLE=1&verifyStatusList=1`
      this.reasonTypeList.map(v => {
        params += `&refundReasonTypeIdList=${v.id}`
      })
      let config = {
        headers: {
          ['Bm-Area-Id']: sessionStorage.getItem('currentAreaId')
        }
      }
      this.$http.get('boom-center-search-service/sysAdmin/orderRefund/page?' + params, config).then(res => {
        this.pendingNum = res.total
      })
    },
    // 改变展示
    changeShow(flag, dataInfo) {
      if (dataInfo) {
        this.afterRefundInfo = dataInfo
      }
      this.compensateShow = flag
    }
  },
  mounted() {
    this.getRefundReasonList()
    this.getReasonTypeList()
  },
  watch: {
    'search.afterSaleType'() {
      this.getList(false, false)
    }
  }
}
</script>

<style lang="less" scoped>
.table {
  .product-info {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin-right: 10px;
    }
    .info {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.searchInput,
.btn {
  margin: 10px;
}
</style>
